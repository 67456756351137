import { $, getLang, isValidMessage, setInSession, getFromSession } from './utils';

try {
  const message = JSON.parse(decodeURIComponent(atob(location.hash.slice(1))));
  if (!isValidMessage(message)) {
    throw new Error('Invalid message');
  }
  setInSession('VIEW_MESSAGE', message);
} catch (error) {}

const redirect = (() => {
  const lang = getLang();
  const message = getFromSession('VIEW_MESSAGE');
  const hasSigned = (new URLSearchParams(location.search)).get('state') === 'thank-you';
  const hasWrongLang = $('html').lang !== lang;
  const haveToSignIn = !sessionStorage.getItem('SIGNED_IN');
  const isReceptor = !!sessionStorage.getItem('VIEW_MESSAGE');
  const isCreatorSignIn = ['/', '/index_creador.es.html', '/index_creador.en.html'].includes(location.pathname);
  const isReceiverSignIn = ['/index_receptor.es.html', '/index_receptor.en.html'].includes(location.pathname);
  const isSignIn = isCreatorSignIn || isReceiverSignIn;

  /*if ((haveToSignIn && isSignIn && !hasWrongLang || (!haveToSignIn && !hasWrongLang))) {
    return null;
  }*/

  if (hasSigned) {
    const option = ({ oso: 'oso', piruleta: 'caramelo', helado: 'paleta' })[message && message.option];
    return option
      ? `/receptor_${option}.${lang}.html`
      : `/creador_opciones_oso_1.${lang}.html`;
  }

  if ((haveToSignIn && isSignIn && hasWrongLang) || (haveToSignIn && !isSignIn)) {
    const path = isReceptor ? 'index_receptor' : 'index_creador';
    return `/${path}.${lang}.html`;
  }

  if (hasWrongLang) {
    const urlExt = /\.[a-z]{2}\.html/.exec(location.pathname)[0];
    return location.pathname.replace(urlExt, `.${lang}.html`);
  }
})();

if (redirect) {
  location.assign(redirect);
}
